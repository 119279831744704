import Vue from 'vue'
import { RESOURCE_PRODUCTS } from '@/shared/constants/resources'
import { BaseApi } from '@/api/base-api'

class ProductsApi extends BaseApi {
  constructor(resource) {
    super(resource)
    this.resource = resource
  }

  deleteProductImage(productId) {
    return Vue.prototype.$http.delete(`${this.resource}/delete_image/${productId}`)
  }
}

export default new ProductsApi(RESOURCE_PRODUCTS)
