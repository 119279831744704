<template>
  <div>
    <b-card no-body>
      <b-card-title
        tag="h1"
        class="m-0 py-1 px-2"
      >
        <b-row
          align-v="center"
          align-h="between"
        >
          <b-col
            class="font-weight-bold d-flex align-items-center"
            cols="auto"
          >
            <span class="text-capitalize text-dark">{{ product ? product.name : '' }}</span>
          </b-col>
          <b-col cols="auto">
            <b-link
              v-access="{
                resource: $data.$constants.RESOURCES.RESOURCE_PRODUCTS,
                resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
              }"
              :to="{ name: 'editProduct', params: { id: $route.params.id} }"
              v-if="isSuperAdmin"
              class="d-inline-block px-50 text-indigo"
            >
              <feather-icon
                v-b-tooltip.hover 
                title="Editar"
                icon="Edit2Icon"
                size="18"
              />
            </b-link>
            <b-link
              v-access="{
                resource: $data.$constants.RESOURCES.RESOURCE_PRODUCTS,
                resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
              }"
              v-if="isSuperAdmin"
            >
              <span
                class="d-inline-block px-50 text-danger"
                @click="handleDeleteProductButtonClick()"
              >
                <feather-icon
                  v-b-tooltip.hover 
                  title="Eliminar"
                  icon="TrashIcon"
                  size="18"
                />
              </span>
            </b-link>
          </b-col>
        </b-row>
      </b-card-title>
      <hr class="m-0">
      <b-row
        align-v="start"
        class="p-2"
      >
        <b-col
          md="12"
          lg="9"
        >
          <b-row>
            <b-col
              sm="12"
              md="6"
              lg="4"
            >
              <div class="font-weight-bold">
                Categoría - Subcategoría - clase:
              </div>
              <div class="text-light pb-1">
                {{ categoryText }}
              </div>
            </b-col>
            <b-col
              sm="12"
              md="6"
              lg="4"
            >
              <div class="font-weight-bold">
                Marca:
              </div>
              <div class="text-light pb-1">
                {{ brandText }}
              </div>
            </b-col>
            <b-col
              sm="12"
              md="6"
              lg="4"
            >
              <div class="font-weight-bold">
                Fabricante:
              </div>
              <div class="text-light pb-1">
                {{ makerText }}
              </div>
            </b-col>
            <b-col
              sm="12"
              md="6"
              lg="4"
            >
              <div class="font-weight-bold">
                Proveedor principal:
              </div>
              <div class="text-light pb-1">
                {{ majorProviderText }}
              </div>
            </b-col>
            <b-col
              sm="12"
              md="6"
              lg="4"
            >
              <div class="font-weight-bold">
                Proveedor secundario:
              </div>
              <div class="text-light pb-1">
                {{ secondaryProviderText }}
              </div>
            </b-col>
            <b-col
              sm="12"
              md="6"
              lg="4"
            >
              <div class="font-weight-bold">
                SKU:
              </div>
              <div class="text-light pb-1">
                {{ skuText }}
              </div>
            </b-col>
            <b-col
              sm="12"
              md="6"
              lg="4"
            >
              <div class="font-weight-bold">
                Producto de compra:
              </div>
              <div class="text-light">
                {{ purchasePriceText }}
              </div>
              <div class="text-light pb-1">
                {{ purchaseTaxText }}
              </div>
            </b-col>
            <b-col
              sm="12"
              md="6"
              lg="4"
            >
              <div class="font-weight-bold">
                Producto de venta:
              </div>
              <div class="text-light">
                {{ salePriceText }}
              </div>
              <div class="text-light pb-1">
                {{ saleTaxText }}
              </div>
            </b-col>
            <b-col
              sm="12"
              md="6"
              lg="4"
            >
              <div class="font-weight-bold">
                Grupo:
              </div>
              <div class="text-light pb-1">
                {{ productGroup }}
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          md="12"
          lg="3"
        >
          <b-img
            v-if="product && product.image"
            :src="product.image.path"
            :height="180"
            alt="profile image"
          />
        </b-col>
      </b-row>
      <template v-if="productAttrProfile.length > 0 ">
        <h3 class="text-uppercase font-weight-bold mb-0 px-2">
          Campos personalizados
        </h3>
        <ProfilePanel :profile="productAttrProfile" />
      </template>
    </b-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ProductsApi from '@/api/products-api'
import ProfilePanel from '@/components/ui/panel/ProfilePanel.vue'

export default {
  components: { ProfilePanel },
  data() {
    return {
      product: null,
    }
  },
  computed: {
    productAttrProfile() {
      if (!this.product?.attrValues) {
        return []
      }

      return this.product.attrValues.reduce((accumulator, currentValue) => {
        accumulator.push({ label: currentValue.attrib_configuration.name, value: currentValue.value })
        return accumulator
      }, [])
    },
    categoryText() {
      return this.product?.category?.name || 'Sin categoría'
    },
    brandText() {
      return this.product?.brand || 'Sin marca'
    },
    makerText() {
      return this.product?.maker || 'Sin fabricante'
    },
    majorProviderText() {
      return this.product?.major_provider?.name || 'Sin proveedor principal'
    },
    secondaryProviderText() {
      return this.product?.secondary_provider?.name || 'Sin proveedor secundario'
    },
    productGroup() {
      return this.product?.product_group || 'Sin grupo'
    },
    skuText() {
      return this.product?.sku || 'Sin SKU'
    },
    purchasePriceText() {
      return `Coste unitario ${this.$options.filters.numberToLocalString(this.product?.purchase_price)} €`
    },
    purchaseTaxText() {
      return `Impuesto de venta ${this.product?.purchase_tax || 0} %`
    },
    salePriceText() {
      return `Precio unitario ${this.$options.filters.numberToLocalString(this.product?.sale_price)} €`
    },
    saleTaxText() {
      return `Impuesto de venta ${this.product?.sale_tax || 0} %`
    },
    isSuperAdmin() {
      return JSON.parse(localStorage.getItem('userData')).roles[0].name === 'super_admin'
    },
  },
  mounted() {
    this.loadProduct()
  },
  methods: {
    ...mapActions('app', ['setLoading']),
    async loadProduct() {
      this.setLoading(true)
      try {
        const response = await ProductsApi.get(this.$route.params.id)
        this.product = response.data
      } finally {
        this.setLoading(false)
      }
    },
    async handleDeleteProductButtonClick() {
      if (!this.product) {
        return
      }

      const response = await this.$modal.show({
        title: '¿Está seguro?',
        text: `Se borrará el registro ${this.product.name}.`,
      })

      if (!response.isConfirmed) {
        return
      }

      await this.deleteProduct()
    },
    async deleteProduct() {
      this.setLoading(true)
      try {
        await ProductsApi.delete(this.product.id)
        await this.$router.push({ name: 'products' })
      } finally {
        this.setLoading(false)
      }
    },
  },
}
</script>

<style scoped>
</style>
